import React, { useState } from "react"
import "./SpilleSpill.css"
import { GiMoneyStack } from "react-icons/gi"

interface IPlayerObj {
  name: string
  money: number
  interest: number
  loan: number
  bankrupt: boolean
  id: string
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export default () => {
  const [players, setPlayers] = useState<IPlayerObj[]>([])

  const increase = (id: string) => {
    let temp = [...players]
    const obj = temp.findIndex(t => t.id === id)

    if (temp[obj] && temp[obj].money && temp[obj].interest) {
      temp[obj].interest += 1
      setPlayers(temp)
    }
  }

  const decrease = (id: string) => {
    let temp = [...players]
    const obj = temp.findIndex(t => t.id === id)

    if (temp[obj] && temp[obj].money && temp[obj].interest) {
      temp[obj].interest -= 1
      setPlayers(temp)
    }
  }

  const pay = (id: string, amount: number) => {
    let temp = [...players]
    const obj = temp.findIndex(t => t.id === id)

    if (amount < 0) {
      alert("Du er konkurs")
      temp[obj].loan = 0
      temp[obj].money = 0
      temp[obj].bankrupt = true
    }

    temp[obj].money -= amount
    setPlayers(temp)
  }

  return (
    <div className="SpilleSpill">
      <div className="top">
        <div className="row">
          <GiMoneyStack />
          <h1>SpilleSpill Bank App</h1>
        </div>
        <p>Valuta i spillet er BINSJ</p>
      </div>

      <div className="btnGroup row">
        <button
          className="btn"
          onClick={() => {
            let temp = [...players]
            let v = 0
            for (let i = 0; i < temp.length; i++) {
              if (temp[i].money > 0) {
                temp[i].money =
                  temp[i].money + temp[i].money * (temp[i].interest / 100)
                temp[i].money = Math.round(temp[i].money)
              } else {
                v = 1
              }

              if (temp[i].loan > 0) {
                temp[i].loan + temp[i].loan * (temp[i].interest / 100)
                temp[i].loan = Math.round(temp[i].loan)
              }
            }

            if (temp.length === 0) {
              alert("Det er ingen spillere, derfor er det ikke vits å gi rente")
            }

            if (v === 1) {
              alert(
                "Noen spillere har ikke egenkapital, og kan dermed ikke få noen rente"
              )
            }
            setPlayers(temp)
          }}
        >
          Ny runde
        </button>

        <button
          className="btn middleBtn"
          onClick={() => {
            let name = prompt("Navn på spiller:")
            if (name) {
              let temp = [...players]
              let id = uuidv4()
              temp.push({
                name,
                interest: 2,
                money: 100,
                id,
                loan: 0,
                bankrupt: false,
              })
              console.log(id)

              setPlayers(temp)
            }
          }}
        >
          Lag ny spiller
        </button>

        <button
          className="btn"
          onClick={() => {
            let winner: IPlayerObj = players[1]
            players.forEach(element => {
              if (element.money > winner.money)  {
                winner = element
              }

              alert(`Vinneren er ${winner.name}`)
            });
          }}
        >
          Kåre vinner
        </button>
      </div>

      <ul>
        {players.map(player => (
          <li className={player.bankrupt ? "bankrupt row" : "row"}>
            <div className="valueHolder column">
              <p style={{marginBottom: 7}} className="name">{player.name}</p>
              <p className="loan">Aktivt lån på {player.loan} BINSJ</p>
              <p className="egen">
                Egenkapital på {player.money - player.loan} BINSJ
              </p>
              <p className="total">Totalkapital på {player.money} BINSJ</p>
            </div>

            <div className="interestHolder valueHolder row">
              <p className="interest">
                <b>{player.interest}%</b> rente
              </p>
              <div className="btnVGroup row">
              <button 
                style={{ borderRight: "1px solid black" }}
                onClick={() => {
                  decrease(player.id)
                }}
              >
                -
              </button>
              <button
                onClick={() => {
                  increase(player.id)
                }}
              >+</button>
              </div>
            </div>
            <div className="column liBtnGroup">
              <button
                className="btn"
                onClick={() => {
                  const amountToPay = prompt("Hvor mye skal trekkes: ")
                  if (amountToPay != null) {
                    if (parseInt(amountToPay) === NaN) {
                      alert("Du må skrive inn et nummer")
                    } else {
                      pay(player.id, parseInt(amountToPay))
                    }
                  }
                }}
              >
                Betal
              </button>

              <button
                className="btn"
                onClick={() => {
                  const moneyStr = prompt("Hvor mye har du tjent: ")
                  if (moneyStr != null) {
                    const money = parseInt(moneyStr)
                    if (money === NaN) {
                      alert("Du må skrive inn et nummer")
                      return
                    }

                    if (money < 0) {
                      alert("Du kan ikke betale inn et negativt tall")
                    }

                    let temp = [...players]
                    const obj = temp.findIndex(t => t.id === player.id)
                    temp[obj].money += money
                    setPlayers(temp)
                  }
                }}
              >
                Tjene penger
              </button>

              <button
                className="btn"
                onClick={() => {
                  const loanSumStr = prompt("Hvor mye skal du låne: ")
                  if (loanSumStr != null) {
                    const loanSum = parseInt(loanSumStr)
                    if (loanSum === NaN) {
                      alert("Du må skrive inn et nummer")
                      return
                    }

                    if (loanSum * 0.2 > player.money) {
                      alert(
                        "Du har ikke nok egenkapital. Du må ha minst 20% av lånesummen"
                      )
                      return
                    }

                    let temp = [...players]
                    const obj = temp.findIndex(t => t.id === player.id)
                    temp[obj].loan += loanSum
                    temp[obj].money += loanSum
                    setPlayers(temp)
                  }
                }}
              >
                Lån penger
              </button>

              <button
                className="btn"
                onClick={() => {
                  const howMuchToPayStr = prompt("Hvor mye nedbetaling: ")
                  if (howMuchToPayStr != null) {
                    const howMuchToPay = parseInt(howMuchToPayStr)
                    if (howMuchToPay === NaN) {
                      alert("Du må skrive inn ett nummer")
                      return
                    }

                    if (howMuchToPay > player.loan) {
                      alert(
                        "Du prøver å betale tilbake mer enn du har lånt, det går ikke"
                      )
                      return
                    }

                    if (howMuchToPay > player.money) {
                      alert("Du har ikke nok penger å betale tilbake")
                      return
                    }

                    let temp = [...players]
                    const obj = temp.findIndex(t => t.id === player.id)
                    temp[obj].loan -= howMuchToPay
                    temp[obj].money -= howMuchToPay
                    setPlayers(temp)
                  }
                }}
              >
                Betal ned lån
              </button>


            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
